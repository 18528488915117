



















import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component({
  name: 'DialogTimePicker',
})
export default class DialogTimePicker extends Vue {
  @VModel({ type: String }) time!: string;

  @Prop(String) readonly label!: string;

  dialog = false;
}
