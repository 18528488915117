/* eslint-disable @typescript-eslint/camelcase */
export interface RawTransaction {
  uuid: string;
  description: string;
  created_datetime: string;
  amount: number;
  type: number;
}

type KeyToSerialize = 'created_datetime';
type PrimitiveTransaction = Omit<RawTransaction, KeyToSerialize>;

export default class Transaction implements PrimitiveTransaction {
  uuid = '';

  description = '';

  created_datetime = new Date(0);

  amount = 0;

  type = 0;

  constructor(json?: RawTransaction) {
    if (!json) {
      return;
    }
    this.assignJson(json);
  }

  assignJson(json: RawTransaction) {
    Object.assign(this, json);
    this.created_datetime = new Date(json.created_datetime);
  }
}

export const TRANSACTION_TYPE = {
  IN: 1,
  OUT: 2,
};

export const EMPTY_TRANSACTION: RawTransaction = Object.freeze(
  Object.seal({
    uuid: '',
    description: '',
    created_datetime: '',
    amount: 0,
    type: 0,
  })
);
