


















import { Vue, Component, Prop } from 'vue-property-decorator';
import QrCode from './QrCode.vue';

@Component({
  name: 'QrCodeDialog',
  components: {
    QrCode,
  },
})
export default class QrCodeDialog extends Vue {
  @Prop({ required: true }) value!: string;

  dialog = false;
}
