




















































import { Vue, Component, Ref } from 'vue-property-decorator';
import { BANK_LIST } from '@/common/models/Banks';
import DialogTimePicker from '@/components/DialogTimePicker.vue';
import DialogDatePicker from '@/components/DialogDatePicker.vue';
import { requestWalletDeposit } from '@/common/service/WalletApi';

import { isValidCurrencyFormat } from '@/common/functions/moneyFormat';

@Component({
  name: 'DepositView',
  components: {
    DialogTimePicker,
    DialogDatePicker,
  },
})
export default class DepositView extends Vue {
  @Ref('bankForm') readonly bankForm!: HTMLFormElement;

  banks = BANK_LIST.filter((b) => b.isActive);

  selectedBank = -1;

  reference = '';

  account = '';

  amount = '';

  hasReference = true;

  selectingDepositDate = false;

  selectingDepositTime = false;

  depositAccount = '';

  depositName = '';

  depositDetail = '';

  depositDate = '';

  depositTime = '';

  rules = {
    reference: [(v: string) => !!v || 'Referencia es requerida'],
    bank: [(v: number) => v !== -1 || 'Banco es requerido'],
    currency: [(v: string) => isValidCurrencyFormat(v) || 'Tiene que tener el formato de moneda'],
  };

  get bankNames() {
    return this.banks.map((b) => b.label);
  }

  get helpText() {
    if (this.selectedBank === -1) {
      return '';
    }
    return this.banks.find((b) => b.id === this.selectedBank)?.helpText;
  }

  async requestDeposit() {
    const valid = this.bankForm.validate();
    if (!valid) {
      return;
    }
    const depositPayload = {
      reference: this.bankReference,
      bank: this.selectedBank,
      amount: this.parsedAmount,
    };
    await requestWalletDeposit(this.$route.params.id, depositPayload);
    this.$router.back();
  }

  showNoReferenceFormDetail() {
    if (!this.hasReference) {
      this.hasReference = true;
      return;
    }
    this.hasReference = false;
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    this.depositDate = `${year}-${month}-${day}`;
    this.depositTime = `${hour}:${minute}`;
  }

  get parsedAmount() {
    const [units, decimals = '00'] = this.amount.split('.');
    const moneyValue = Number(units) + Number(decimals.padEnd(2, '0')) / 100;
    return moneyValue;
  }

  get formattedAmount() {
    return `HNL. ${this.parsedAmount.toFixed(2)}`;
  }

  get bankReference() {
    if (this.hasReference) {
      return this.reference;
    }
    if (!(this.depositAccount || this.depositName)) {
      return '';
    }
    return `[${this.depositAccount}] ${this.depositName} - ${this.depositDetail} - ${this.depositDate} ${this.depositTime}`;
  }

  set bankReference(value: string) {
    this.reference = value;
  }
}
