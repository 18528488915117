
















import { Vue, Component, Prop } from 'vue-property-decorator';
import Transaction, { TRANSACTION_TYPE } from '@/common/models/Transaction';

import dateTimeFormat from '@/common/functions/dateTimeFormat';

@Component({
  name: 'TransactionDetail',
  filters: {
    json: (text: string) => JSON.stringify(text, null, 2),
    dateTimeFormat,
  },
})
export default class TransactionDetail extends Vue {
  @Prop({ required: true })
  transaction!: Transaction;

  get typeText() {
    if (this.transaction.type === TRANSACTION_TYPE.IN) {
      return 'Entrante';
    }
    if (this.transaction.type === TRANSACTION_TYPE.OUT) {
      return 'Saliente';
    }
    return '';
  }
}
